<template>
  <div id="app">
    <div class="container-fluid m-0 p-0">
      <b-navbar toggleable="lg" type="dark" class="nav-main">
      <img src="./assets/logo.png" class="logo d-inline-block align-top" alt="Logo Roadhouse">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <!-- <b-nav-item active><router-link to="/">Main Page</router-link></b-nav-item>
        <b-nav-item active><router-link to="/detail">Detail Page</router-link></b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
          <h4 right class="title">Timeslots Viewer</h4>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
    
    </div>
    <div class="container-fluid">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.logo {
  max-height: 40px;
}

.title {
  margin-top: 12px;
  color: white;
}

.nav-main {
  background-color: #690b17 !important;
}

body {
    background-color: #ad192b !important;
}

.table-hover tbody tr:hover {
  cursor: pointer !important;
}
</style>
