<template>
  <div>
    <b-card class="text-center mt-3">
    <div>
        <div class="input-group mb-3">
        <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">Ricerca su nome...</span>
        </div>
            <b-form-input v-model="ricercaValue" type="text" debounce="500" @input="ricerca"></b-form-input>
        </div>
        <b-alert v-if="isError" class="mt-4" show variant="danger">Errore di chiamata<br/>
            <b-button class="mt-2" @click="getStore()">Riprova</b-button>
        </b-alert>
         <b-table
            class="mt-2"
            striped
            hover
            responsive
            :items="items"
            :fields="fields"            
            :busy="isBusy"
            @row-clicked="info"
        >
         <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>
        </b-table>
    </div>
    </b-card>
  </div>
</template>

<script>
import * as _ from 'loadsh';
import { httpClientMixin } from '../../../mixin/httpClient';

export default {
  name: 'MainPage',
  mixins: [httpClientMixin],
  props: {
    msg: String
  },
  methods: {
      ricerca(event) {
            this.items = _.filter(this.inputData, function(o) { return o.name.toLowerCase().includes(event.toLowerCase()); });
      },
      getStore() {
        this.isBusy = true;
        this.getStores().then((response) => {

          this.inputData = response.data.data;
          this.items = [...this.inputData];
          this.isBusy = false;
          this.isError = false;
        }).catch( () => {
            this.isError = true;
            this.isBusy = false;
        })
      },
      info(item) {
        this.$router.push({
        name: 'detail-page',
          params: { id: item.tcposId }
        })
      },
  },
  mounted() {
    this.getStore();
  },
  data() {
      return {
        fields: [
          { key: 'tcposId',
            label: 'Tcpos Id'
          },
          { key: 'name',
            label: 'Nome'
          },
          { 
            key: 'fullAddress',
            label: 'Indirizzo'
           },
           { key: 'phone',
            label: 'Telefono'
           }],
        inputData: [],
        isError: false,
        isBusy: false,
        items: [],
        ricercaValue: ''
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
