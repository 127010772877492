<template>
  <div>
        <b-card class="mt-2" title="Timeslots" :sub-title="this.store ? this.store.name : null">
        
        <b-alert v-if="timeslots && timeslots.length === 0" class="mt-4" show>Nessun Timeslot impostato</b-alert>

        <b-alert v-if="isError" class="mt-4" show variant="danger">Errore di chiamata<br/>
            <b-button class="mt-2" @click="getData()">Riprova</b-button>
        </b-alert>

        <b-card-text>
           <b-table
           class="mt-4"
              striped
              responsive
              :busy="isBusy"
              :items="timeslots"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
              </div>
            </template>

            <template #cell(Pranzo)="data">
              <b>{{ data.value }}</b>
            </template>
            <template #cell(Cena)="data">
              <b>{{ data.value }}</b>
            </template>

          </b-table>
        </b-card-text>

        <router-link class="card-link mt-3" to="/">
          <b-button>Torna alla lista dei negozi</b-button>
        </router-link>
      </b-card>
  </div>
</template>

<script>
import { httpClientMixin } from '../../../mixin/httpClient';
import * as _ from 'loadsh';
export default {
  name: 'DetailPage',
  mixins: [httpClientMixin],
  props: {
    msg: String
  },
  methods: {
    getData(tcposid) {
      this.isBusy = true;
      this.getTimeSlotOfStore(tcposid).then( res => {
        this.store = res.data.data.store;
        this.isError = false;

        this.timeslots = _.map(res.data.data.timeslots, (item, key) => {
          let obg = {};
          obg.giorno = this.weekday[key];

          const pranzo = _.find(item.sessions, ['name', 'Pranzo']);
          const cena = _.find(item.sessions, ['name', 'Cena']);

          
          if(pranzo && cena) {
            obg[this.sessioni[0]] = _.first(pranzo.timeslots) + ' / ' + _.last(pranzo.timeslots); 
            obg[this.sessioni[1]] = _.first(cena.timeslots) + ' / ' + _.last(cena.timeslots);          
          }

          if(pranzo && !cena) {
            obg[this.sessioni[0]] = _.first(pranzo.timeslots) + ' / ' + _.last(pranzo.timeslots);    
            obg[this.sessioni[1]] = 'Disabilitato';                
          }

          if(!pranzo && cena) {
            obg[this.sessioni[0]] = 'Disabilitato';    
            obg[this.sessioni[1]] = _.first(cena.timeslots) + ' / ' + _.last(cena.timeslots);               
          }
      
          console.log(obg);
          return obg;
        })
    console.log(this.timeslots);
        this.isBusy = false;
      }).catch( () => {
        this.isError = true;
        this.isBusy = false;
      })
    }
  },
  mounted() {
    this.getData(this.$route.params.id);
    this.storeName = this.$route.params.name;
  },
  data() {
    return {
      timeslots: null,
      store: null,
      isBusy: false,
      isError: false,
      weekday: {
        0: 'Domenica',
        1: 'Lunedì',
        2: 'Martedì',
        3: 'Mercoledì',
        4: 'Giovedì',
        5: 'Venerdì',
        6: 'Sabato'
      },
      sessioni: {
        0: 'Pranzo',
        1: 'Cena'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
