import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { TablePlugin, CardPlugin, FormInputPlugin, NavbarPlugin, NavPlugin, AlertPlugin, SpinnerPlugin, ButtonPlugin } from 'bootstrap-vue'
import MainPage from './components/pages/main/MainPage'
import DetailPage from './components/pages/detail/DetailPage'
import axios from 'axios';
import VueAxios from 'vue-axios'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(TablePlugin), Vue.use(CardPlugin), Vue.use(FormInputPlugin), Vue.use(NavbarPlugin), Vue.use(NavPlugin), Vue.use(AlertPlugin), Vue.use(SpinnerPlugin), Vue.use(ButtonPlugin);
// Optionally install the BootstrapVue icon components plugin
Vue.use(VueRouter)

// Axios
Vue.use(VueAxios, axios)


const routes = [
  { path: '/', component: MainPage },
  { 
    path: '/detail/:id',
    name: 'detail-page',
    component: DetailPage,
    props: (route) => ({
      ...route.params
    })
  }
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})


new Vue({
  render: h => h(App),
  router
}).$mount('#app')
