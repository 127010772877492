export var httpClientMixin  = {
    methods: {
        // Metodi tipo Prodotto
        getStores() {
            return this.$http.get(process.env.VUE_APP_BASE_API + 'public/stores');
        },
        getTimeSlotOfStore(tcposid) {
            return this.$http.get(process.env.VUE_APP_BASE_API + 'public/stores/' + tcposid + '/takeaway/slots');
        }
    }
}